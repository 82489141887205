import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const UploadPricatInfoBlock = () => {
  const { pricatUploadResponse } = useSelector((state) => state.data);
  return (
    <Box display="grid" columnGap={2} gridTemplateColumns="max-content max-content">
      <Typography display="inline" fontWeight="bold" variant="subtitle2">
      Supplier
      </Typography>
      <Typography display="inline" sx={{ mr: 1, fontWeight: '200' }} variant="subtitle2">
        {pricatUploadResponse?.supplier}
      </Typography>

      <Typography display="inline" fontWeight="bold" variant="subtitle2">
        Currency
      </Typography>
      <Typography display="inline" sx={{ mr: 1, fontWeight: '200' }} variant="subtitle2">
        {pricatUploadResponse?.currency}
      </Typography>

      <Typography display="inline" fontWeight="bold" variant="subtitle2">
        Price start date
      </Typography>
      <Typography display="inline" sx={{ mr: 1, fontWeight: '200' }} variant="subtitle2">
        {pricatUploadResponse?.priceStartDate}
      </Typography>

      <Typography display="inline" fontWeight="bold" variant="subtitle2">
        Price end date
      </Typography>
      <Typography display="inline" sx={{ mr: 1, fontWeight: '200' }} variant="subtitle2">
        {pricatUploadResponse?.priceEndDate}
      </Typography>

      <Typography display="inline" fontWeight="bold" variant="subtitle2">
        Items in import
      </Typography>
      <Typography display="inline" sx={{ mr: 1, fontWeight: '200' }} variant="subtitle2">
        {pricatUploadResponse?.pricatData?.length}
      </Typography>
    </Box>
  );
};

export default UploadPricatInfoBlock;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('token') || null,
  // isAuthenticated: !!localStorage.getItem('token') || null,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveToken(state, { payload }) {
      if (payload) {
        localStorage.setItem('token', payload);
        state.token = payload;
        // state.isAuthenticated = true;
      }
    },
    clearToken(state) {
      state.token = null;
      state.error = 'You are not authorized, please relogin.';
      // state.isAuthenticated = false;
      localStorage.removeItem('token');
    },
  },
});

export const { saveToken, clearToken, setAuthState } = auth.actions;
export default auth.reducer;

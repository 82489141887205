import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { saveToken } from '../features/auth/authSlice';

const Auth = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useSelector((state) => state.auth);

  const accessToken = location && location.hash && queryString.parse(location.hash).id_token;

  useEffect(() => {
    if (accessToken) {
      dispatch(saveToken(accessToken));
      history.push('/overview');
    }
    if (token) {
      history.push('/overview');
    }
  }, [accessToken, token, dispatch, history]);

  return (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      <CircularProgress size="100px" color="success" />
    </Box>
  );
};

export default Auth;

import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Login from './pages/Login';
import Overview from './pages/Overview';
import Auth from './components/Auth';
import PrivateRoute from './components/PrivateRoute'


/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const Routes = () => (
  <Switch>    
    <Route exact path='/login' component={Login} />
    <Route exact path='/auth' component={Auth} />
    <PrivateRoute>
      <Route exact path='/overview' component={Overview} />
    </PrivateRoute>
  </Switch>
);

export default Routes;
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

const Login = () => {
  const { token } = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  //
  // const login = () => {
  //   if (!isAuthenticated) {
  //     window.location = process.env.REACT_APP_LOGIN_URL;
  //   }
  // };

  if (token) {
    return <Redirect to="/overview" />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%', background: 'rgba(249, 249, 249, 0.4)' }}
    >
      <Typography variant="h1" color="#525252" sx={{ fontWeight: 'bold' }}>
        Royal Design
      </Typography>      
      <Typography variant="h6" color="#525252" sx={{ fontWeight: 'bold' }}>
        Welcome to Royal Design onboarding portal
      </Typography>
    </Box>
  );
};

export default Login;

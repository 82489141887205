import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StyledUploadContainer from './StyledUploadContainer';

const UploadImageZip = ({ isImageUploading, handleUploadImage }) => (
  <StyledUploadContainer>
    {isImageUploading ? (
      <CircularProgress style={{ color: '#000000d9' }} />
    ) : (
      <label htmlFor="image" style={{ width: '100%', cursor: 'pointer' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <CloudUploadIcon />
          <input
            style={{ display: 'none' }}
            type="file"
            id="image"
            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
            onChange={handleUploadImage}
          />
          <Typography>UPLOAD IMAGE ZIP</Typography>
        </Box>
      </label>
    )}
  </StyledUploadContainer>
);

export default UploadImageZip;

import React, { useState } from 'react';
import { Container, Grid } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { useSelector } from 'react-redux';
import onboardingAPI from '../../services';
import { UploadPricat, SectionHeader, DownloadLink, UploadImageZip } from './overview';
import { DOCUMENTS } from '../../constants';
import UploadPricatDialog from './components/UploadModal/UploadPricatDialog';
import UploadImageDialog from './components/UploadModal/UploadImageDialog';
import PreviousUploads from './components/PreviousUploads';

const Documents = () => {
  // const { data, isLoading, error } = onboardingAPI.useGetUserQuery();
  // const { token } = useSelector((state) => state.auth);
  // const [isLookBookUploading, setIsLookBookUploading] = useState(false);

  const [uploadPricat, { isLoading: isPricatUploading }] = onboardingAPI.useUploadPricatMutation();
  const { pricatUploadResponse } = useSelector((state) => state.data);
  const [isUploadedModal, setIsUploadedModal] = useState(false);
  const [isUploadedImageModal, setIsUploadedImageModal] = useState(false);

  const [uploadImage, { isLoading: isImageUploading }] = onboardingAPI.useUploadImageMutation();
  // const [deletePricat] = onboardingAPI.useDeletePricatMutation();
  const [submitPricat] = onboardingAPI.useSubmitPricatMutation({
    fixedCacheKey: 'shared-submit',
  });
  const { data: userData } = onboardingAPI.useGetUserQuery();
  const { data: vendorInfo } = onboardingAPI.useGetVendorInfoQuery(userData?.activeVendor, {
    skip: !userData?.activeVendor,
  });
  // const { data: uploads } = onboardingAPI.useGetUploadsQuery(vendorInfo?.key, {
  //   skip: !vendorInfo?.key,
  // });

  // const [fileToDelete, setFileToDelete] = useState(null);

  // const handleUploadLookBook = async (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setIsLookBookUploading(true);
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     const { data: resp } = await axios.post(
  //       `/api/fileupload?type=lookbook&vendor=${selectedVendor.id}`,
  //       formData,
  //       {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     event.target.value = '';
  //     refetchUploads(selectedVendor?.id);
  //     setIsLookBookUploading(false);
  //   }
  // };

  // const deleteHandler = (value) => {
  //   if (value) {
  //     deletePricat(fileToDelete.batchId);
  //   }
  //   setFileToDelete(null);
  // };
  //
  // const exprotHandler = () => {
  //   console.log();
  // };

  const handleUploadPricat = async (event) => {
    const selectedFile = event.target?.files[0];   
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      uploadPricat({ formData, vendorId: vendorInfo?.key, name: vendorInfo?.name, currency :vendorInfo?.purchaseCurrency }).then(() => {
        event.target.value = '';
        setIsUploadedModal(true);
      });
    }
  };

  const handleClose = () => {
    setIsUploadedModal(false);
  };

  const handleSubmit = () => {
    submitPricat({ pricat: pricatUploadResponse }).then(() => {
      setIsUploadedModal(false);
    });
  };

  const handleUploadImage = async (event) => {
    const selectedFile = event.target?.files[0];    

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      uploadImage({ formData, vendorId: vendorInfo?.key })
      .then(() => {
        event.target.value = '';
         setIsUploadedImageModal(true);
      });
    }
  };

  const handleImageClose = () => {
    setIsUploadedImageModal(false);
  };

  return (
    <Container maxWidth="xxl" style={{ marginTop: '30px', marginBottom: '30px' }}>
      <Grid container spacing={10} style={{ color: '#3c4858' }}>
        <Grid item container direction="column" xs={4}>
          <SectionHeader>UPLOAD</SectionHeader>
          <DownloadLink
            icon={AttachFileIcon}
            href="/templates/RDTemplateFoundation_v1.xlsx"
            label="Download PRICAT template"
          />
          <UploadPricat handleUploadPricat={handleUploadPricat} isPricatUploading={isPricatUploading} />
          {/* <DownloadLink
            icon={AttachFileIcon}
            href="/templates/image_upload_template.xlsx"
            label="Download Image Metadata template"
          /> */}
          <UploadImageZip handleUploadImage={handleUploadImage}  isImageUploading={isImageUploading}/>
          {/* <UploadLookBook */}
          {/*  handleUploadLookBook={handleUploadLookBook} */}
          {/*  isLookBookUploading={isLookBookUploading} */}
          {/* /> */}
        </Grid>
        <Grid item xs={4}>
          <PreviousUploads />
        </Grid>
        <Grid item xs={4}>
          {/* <SectionHeader>ACTIONS</SectionHeader> */}
          {/* <DownloadLink */}
          {/*  icon={AttachFileIcon} */}
          {/*  href="/templates/Pricat Frilufts FW21.xlsx" */}
          {/*  label="Download PRICAT template" */}
          {/* /> */}
          <SectionHeader>DOCUMENTS</SectionHeader>
          {DOCUMENTS.map(({ fileName, label }) => (
            <DownloadLink
              key={label}
              icon={PictureAsPdf}
              label={label}
              href={`${process.env.REACT_APP_BLOB_DOCUMENTS}${fileName}`}
            />
          ))}
        </Grid>
      </Grid>
      <UploadPricatDialog isUploadedModal={isUploadedModal} handleClose={handleClose} handleSubmit={handleSubmit} />
      <UploadImageDialog isUploadedImageModal={isUploadedImageModal} handleImageClose={handleImageClose} />
      {/* <DeleteDialog open={Boolean(fileToDelete) || false} fileToDelete={fileToDelete} onClose={deleteHandler} /> */}
    </Container>
  );
};
export default Documents;

import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { COLUMNS_TO_SHOW } from '../../../../../constants';

const SubmitStepBlock = () => {
  const { pricatUploadResponse } = useSelector((state) => state.data);
  const pricatsToShow = pricatUploadResponse?.pricatData?.slice(0, 1000)
  return (
    <Box sx={{ overflow: 'auto', height: '465px' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(10, minmax(46px, max-content))',
          borderTop: '1px solid #f1ebeb',
          borderRight: '1px solid #f1ebeb',
          width: '1000px',
        }}
      >
        {COLUMNS_TO_SHOW.map((column) => (
          <span
            key={column.label}
            style={{
              padding: '4px',
              borderLeft: '1px solid #f1ebeb',
              borderBottom: '1px solid #f1ebeb',
              display: 'table',
              backgroundColor: '#0000001f',
            }}
          >
            <Typography sx={{ display: 'table-cell', verticalAlign: 'middle' }} variant="subtitle2" fontWeight="bold">
              {column.label}
            </Typography>
          </span>
        ))}       
        {pricatsToShow.map((data) =>
          COLUMNS_TO_SHOW.map((column) => (
            <span
              key={data.uid + column.label}
              style={{
                padding: '4px',
                borderLeft: '1px solid #f1ebeb',
                borderBottom: '1px solid #f1ebeb',
                // display: 'table',
              }}
            >
              <Typography
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', verticalAlign: 'middle' }}
                fontWeight="200"
                fontSize="13px"
              >
                {data[column.id]?.id ? data[column.id]?.value : data[column.id]}
              </Typography>
            </span>
          ))
        )}
      </div>
    </Box>
  );
};

export default SubmitStepBlock;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as uuid from 'uuid';
import { clearToken } from '../features/auth/authSlice';

const onboardingQuery = fetchBaseQuery({
  baseUrl:  process.env.REACT_APP_API,
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const injectIntercpetor = (injective) => async (args, api, extraOptions) => {
  const result = await injective(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(clearToken());
    // try to get a new token
    // const refreshResult = await baseQuery('/refreshToken', api, extraOptions)
    // if (refreshResult.data) {
    //   // store the new token
    //   api.dispatch(tokenReceived(refreshResult.data))
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions)
    // } else {
    //   api.dispatch(loggedOut())
    // }
  }
  return result;
};

const onboardingWithInterceptor = injectIntercpetor(onboardingQuery);
const onboardingAPI = createApi({
  reducerPath: 'onboardingAPI',
  baseQuery: onboardingWithInterceptor,
  tagTypes: ['Uploads', 'User', 'VendorInfo'],
  endpoints: (build) => ({
    deletePricat: build.mutation({
      query: (batchId) => ({
        url: `pricat/${batchId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Uploads'],
    }),
    getUser: build.query({
      query: () => ({ url: 'user' }),
      providesTags: ['User'],
    }),
    getVendorInfo: build.query({
      query: (vendorId) => ({ url: `vendor/${vendorId}` }),
      providesTags: ['VendorInfo'],
    }),
    getUploads: build.query({
      query: (id) => ({ url: `vendor/${id}/uploads` }),
      providesTags: ['Uploads'],
    }),
    validatePricatFiled: build.mutation({
      query: ({ fieldForValidation, country }) => ({
        url: `UploadPricat/validatefield?country=${country}`,
        method: 'PUT',
        body: fieldForValidation,
      }),
    }),
    uploadPricat: build.mutation({
      query: ({ formData, vendorId, vendorName, vendorCurrency }) => ({
        url: `uploadpricat?vendor=${vendorId}&name=${vendorName}&currency=${vendorCurrency}`,
        method: 'POST',
        body: formData,
      }),
      transformResponse: (responseData) => {
        responseData.pricatData = responseData.pricatData.map((pricat) => {
          pricat.uuid = uuid.v4();
          return pricat;
        });
        return responseData;
      },
    }),
    submitPricat: build.mutation({
      query: ({ pricat }) => ({
        url: 'uploadpricat/submit',
        method: 'POST',
        body: pricat,
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: ['Uploads'],
    }),
    setSelectedVendor: build.mutation({
      query: (vendorId) => ({
        url: `user/setvendor/${vendorId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['User'],
    }),
    createPricatExport: build.mutation({
      query: ({ batch, vendorId }) => ({
        url: `createpricatexport/${batch}?vendor=${vendorId}`,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
    }),
    uploadImage: build.mutation({
      query: ({ formData, vendorId}) => ({
        url: `uploadpricat/uploadimage?vendor=${vendorId}`,
        method: 'POST',
        body: formData,
      }),
      transformResponse: (responseData) => {
        responseData.imageMetadatas = responseData.imageMetadatas.map((imageData) => {
          imageData.uuid = uuid.v4();
          return imageData;
        });
        return responseData;
      },
    }),
  }),
});

export default onboardingAPI;

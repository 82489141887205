import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Step,
  StepButton,
  StepConnector,
  stepConnectorClasses,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import styled from '@emotion/styled/macro';
import { LoadingButton } from '@mui/lab';
import ValidationRow from './components/ValidationRow';
import UploadPricatInfoBlock from './components/UploadPricatInfoBlock';
import InvalidPricatUploadBlock from './components/InvalidUploadBlock';
import SubmitStepBlock from './components/SubmitStepBlock';
import onboardingAPI from '../../../../services';

const StyledStepButton = styled(StepButton)(() => ({
  // backgroundColor: 'lightblue',
  borderRadius: '10px',
  width: '70px',
  height: '70px',
  top: '18px',
  padding: 0,
  '& .Mui-active': {
    color: '#7D7D7D',
  },
  '& .Mui-disabled': {
    color: 'grey',
  },
  '& .Mui-completed': {
    color: '#7D7D7D',
  },
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    // top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#7D7D7D',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#7D7D7D',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const UploadPricatDialog = ({ isUploadedModal, handleClose, handleSubmit }) => {
  const { pricatUploadResponse } = useSelector((state) => state.data);
  const [, { isLoading: isSubmittingPricat }] = onboardingAPI.useSubmitPricatMutation({
    fixedCacheKey: 'shared-submit',
  });

  const isShowSubmit = () => !pricatUploadResponse?.invalidTemplate && !pricatUploadResponse?.fileExists;
  const isSubmitDisabled = () => pricatUploadResponse?.hasValidationErrors;

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (activeStep !== 0) {
      setActiveStep(0);
    }
  }, [isUploadedModal]);

  return (
    <Dialog maxWidth="md" fullWidth open={isUploadedModal || false} onClose={handleClose}>
      <DialogContent sx={{ height: '600px' }} dividers>
        <Typography variant="h5" sx={{ p: 2 }} align="center">
          Upload a new batch
        </Typography>
        {(pricatUploadResponse?.invalidTemplate || pricatUploadResponse?.fileExists) && <InvalidPricatUploadBlock />}
        {!pricatUploadResponse?.invalidTemplate && !pricatUploadResponse?.fileExists && (
          <>
            <Stepper connector={<ColorlibConnector />} activeStep={activeStep} alternativeLabel sx={{ height: '80px' }}>
              <Step disabled={false} key={0} sx={{ textAlign: 'center' }}>
                <StyledStepButton
                  onClick={() => {
                    setActiveStep(0);
                  }}
                  icon={<InfoOutlinedIcon />}
                >
                  Details
                </StyledStepButton>
              </Step>
              <Step disabled={false} key={1} sx={{ textAlign: 'center' }}>
                <StyledStepButton
                  onClick={() => {
                    setActiveStep(1);
                  }}
                  icon={<PublishedWithChangesIcon />}
                >
                  <Box display="flex">
                    {isSubmitDisabled() && <WarningAmberIcon color="error" fontSize="small" />}
                    Validation
                  </Box>
                </StyledStepButton>
              </Step>
              <Step
                disabled={isSubmitDisabled()}
                onClick={() => {
                  if (!isSubmitDisabled()) {
                    setActiveStep(2);
                  }
                }}
                key={2}
                sx={{ textAlign: 'center' }}
              >
                <StyledStepButton icon={<BackupOutlinedIcon />}>Submit</StyledStepButton>
              </Step>
            </Stepper>
            {activeStep === 0 && !pricatUploadResponse?.invalidTemplate && !pricatUploadResponse?.fileExists && (
              <UploadPricatInfoBlock />
            )}
            {activeStep === 1 && (
              <>
                {/* <BarcodeCreatorBlock /> */}
                <ValidationRow />
              </>
            )}
            {activeStep === 2 && (
              <>
                <SubmitStepBlock />
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep !== 0 && (
          <Button
            disabled={isSubmittingPricat}
            sx={{ mr: 'auto' }}
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              setActiveStep(activeStep - 1);
            }}
          >
            Back
          </Button>
        )}
        <Button disabled={isSubmittingPricat} sx={{ mr: 2 }} onClick={handleClose}>
          Cancel
        </Button>
        {isShowSubmit() && (
          <Tooltip
            title={activeStep === 1 && isSubmitDisabled() ? 'Please fix all validation errors before submit' : ''}
          >
            <span>
              <LoadingButton
                sx={{ width: '130px' }}
                loading={isSubmittingPricat}
                loadingPosition="start"
                startIcon={<></>}
                variant="contained"
                disabled={activeStep === 1 && isSubmitDisabled()}
                onClick={() => {
                  if (activeStep !== 2) {
                    setActiveStep(activeStep + 1);
                  } else {
                    handleSubmit();
                  }
                }}
              >
                {activeStep !== 2 ? 'Next' : 'Submit'}
              </LoadingButton>
            </span>
          </Tooltip>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadPricatDialog;

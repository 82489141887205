import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const InvalidPricatUploadBlock = () => {
  const { pricatUploadResponse } = useSelector((state) => state.data);
  if (!pricatUploadResponse?.invalidTemplate && !pricatUploadResponse?.fileExists) {
    return null;
  }
  return (
    <Box
      display="flex"
      height="200px"
      width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Typography color="primary" align="center" variant="h4">
        {pricatUploadResponse?.invalidTemplate ? 'Invalid template supplied.' : 'File already exists.'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        {pricatUploadResponse?.invalidTemplate
          ? 'Please download and use the latest version.'
          : 'Please use different name for pricat.'}
      </Typography>
    </Box>
  );
};

export default InvalidPricatUploadBlock;

import { createSlice } from '@reduxjs/toolkit';
import onboardingAPI from '../../services';

const initialState = {
  // selectedVendor: null,
  error: null,
  pricatUploadResponse: null,
  uploads: {
    Lookbook: [],
    Pricat: [],
  },
};

const data = createSlice({
  name: 'data',
  initialState,
  reducers: {
    patchPricatUploadResponse(state, { payload }) {
      state.pricatUploadResponse = payload;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    clearError(state) {
      state.error = null;
    },
    setSelectedVendor(state, { payload }) {
      state.selectedVendor = payload;
    },
    setPricatUploadResponse(state, { payload }) {
      state.pricatUploadResponse = payload;
    },
    setUploads(state, { payload }) {
      state.uploads = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(onboardingAPI.endpoints.uploadPricat.matchFulfilled, (state, { payload }) => {
      state.pricatUploadResponse = payload;
    });
  //  .addMatcher(onboardingAPI.endpoints.uploadImage.matchFulfilled, (state, { payload }) => {
  //   state.pricatUploadResponse = payload;
  // });
},
  // .addMatcher(onboardingAPI.endpoints.validatePricatFiled.matchFulfilled, (state, { payload }) => {
  // console.log(payload);
  // const pricatData = state.pricatUploadResponse.pricatData.map((p) => {
  //   if (p.uuid === arg.uuid) {
  //     const upd = {
  //       [arg.fieldForValidation.id.charAt(0).toLowerCase() +
  //       arg.fieldForValidation.id.slice(1)]: {
  //         id: validationResult.id,
  //         validationMessage: validationResult.validationMessage,
  //         isValid: validationResult.isValid,
  //         value: validationResult.value,
  //       },
  //     };
  //     const updateExistedFiledWithValidation = { ...p, ...upd };
  //     console.log(updateExistedFiledWithValidation);
  //     return {
  //       ...updateExistedFiledWithValidation,
  //       // validationError: !pricatRowIsValid(updateExistedFiledWithValidation)
  //     };
  //   }
  //   return p;
  // });
  // pricatUploadResponseDraft.pricatData = [...pricatData];
  // return pricatUploadResponseDraft;
  // });
});

export const {
  patchPricatUploadResponse,
  setError,
  clearError,
  // setSelectedVendor, setPricatUploadResponse, updatePricatItem, setUploads
} = data.actions;

export default data.reducer;

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledUploadContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '60px',
  cursor: 'pointer',
  backgroundColor: '#eeeeee',
  border: 'dashed 3px black',
  marginBottom: '20px',
  transition: 'background-color 0.3s ease',
  ':hover': {
    backgroundColor: theme.palette.primary.light,
    '& label': {
      color: '#eeeeee',
    },
  },
}));

// const StyledUploadContainer = ({ children }) => (
//   <Box
//     sx={{
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       height: '60px',
//       cursor: 'pointer',
//       backgroundColor: '#eeeeee',
//       border: 'dashed 3px #00421b',
//       marginBottom: '20px',
//     }}
//   >
//     {children}
//   </Box>
// );

export default StyledUploadContainer;

export const DOCUMENTS = [
  {
    fileName: 'How%20to%20onboard%20data.pdf',
    label: 'How to onboard data',
  },
  // {
  //   fileName: 'requirements%20on%20images.pdf',
  //   label: 'Requirements on Images',
  //  },
  // {
  //   fileName: 'Third%20Royal%Design%20Document.pdf',
  //   label: 'Third Royal Design Document',
  // },
  // {
  //   fileName: 'Fourth%20Royal%Design%20Document.pdf',
  //   label: 'Fourth Royal Design Document',
  // },
  // {
  //   fileName: 'Fifth%20Royal%Design%20Document.pdf',
  //   label: 'Fifth Royal Design Document',
  // },
  // {
  //   fileName: 'Sixth%20Royal%Design%20Document.pdf',
  //   label: 'Sixth Royal Design Document',
  // },
  // {
  //   fileName: 'Seventh%20Royal%Design%20Document.pdf',
  //   label: 'Seventh Royal Design Document',
  // },
  // {
  //   fileName: 'Eight%20Royal%Design%20Document.pdf',
  //   label: 'Eight Royal Design Document',
  // },
];

export const COLUMNS_TO_SHOW = [
  {
    id: 'productName',
    label: 'Product Name',
  },
  {
    id: 'productSKU',
    label: 'Product SKU',
  },
  {
    id: 'eAN',
    label: 'EAN',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'orderBatchSize',
    label: 'Order Batch Size',
  },
  {
    id: 'tollNumber',
    label: 'Toll Number',
  },
  {
    id: 'weight',
    label: 'Weight',
  },
  {
    id: 'widthCm',
    label: 'Width',
  },
  {
    id: 'depthCm',
    label: 'Depth',
  },
  {
    id: 'colour',
    label: 'Colour',
  },
];

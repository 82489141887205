import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Step,
  StepButton,
  StepConnector,
  stepConnectorClasses,
  Stepper,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from '@emotion/styled/macro';
import UploadImageInfoBlock from './components/UploadImageInfoBlock';
import InvalidPricatUploadBlock from './components/InvalidUploadBlock';

const StyledStepButton = styled(StepButton)(() => ({
  borderRadius: '10px',
  width: '70px',
  height: '70px',
  top: '18px',
  padding: 0,
  '& .Mui-active': {
    color: '#7D7D7D',
  },
  '& .Mui-disabled': {
    color: 'grey',
  },
  '& .Mui-completed': {
    color: '#7D7D7D',
  },
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    // top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#7D7D7D',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#7D7D7D',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const UploadImageDialog = ({ isUploadedImageModal, handleImageClose }) => {
  const { imageUploadResponse } = useSelector((state) => state.imageData);

  const [activeStep, setActiveStep] = useState(0);

  return (
    <Dialog maxWidth="md" fullWidth open={isUploadedImageModal || false} onClose={handleImageClose}>
      <DialogContent sx={{ height: '600px' }} dividers>
        <Typography variant="h5" sx={{ p: 2 }} align="center">
         Image upload result
        </Typography>
        {(imageUploadResponse?.invalidTemplate || imageUploadResponse?.fileExists) && <InvalidPricatUploadBlock />}
        {!imageUploadResponse?.invalidTemplate && !imageUploadResponse?.fileExists && (
          <>
            <Stepper connector={<ColorlibConnector />} activeStep={activeStep} alternativeLabel sx={{ height: '80px' }}>
              <Step disabled={false} key={0} sx={{ textAlign: 'center' }}>
                <StyledStepButton
                  onClick={() => {
                    setActiveStep(0);
                  }}
                  icon={<InfoOutlinedIcon />}
                >
                  Details
                </StyledStepButton>
              </Step>              
            </Stepper>
            {activeStep === 0 && !imageUploadResponse?.invalidTemplate && !imageUploadResponse?.fileExists && !imageUploadResponse?.hasValidationErrors && (
              <UploadImageInfoBlock />
            )}
            {activeStep === 0 && imageUploadResponse?.hasValidationErrors && (
                <>
                    <Typography align="center" fontWeight="bold" variant="subtitle2" style={{'color':'red'}}>
                        Article Number does not exist: {imageUploadResponse.articleNumbers?.join(', ')} Upload was not successfull
                    </Typography>
                </>
            )}
            
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button sx={{ mr: 2 }} onClick={handleImageClose}>
          Ok
        </Button>        
      </DialogActions>
    </Dialog>
  );
};

export default UploadImageDialog;

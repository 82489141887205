import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const UploadImageInfoBlock = () => {
  const { imageUploadResponse } = useSelector((state) => state.imageData);
  const articleNumbers = imageUploadResponse?.articleNumbers;
  const articleNumberString = articleNumbers?.join(', ');
  return (
    <Box display="grid" columnGap={2} gridTemplateColumns="max-content max-content">
      <Typography display="inline" fontWeight="bold" variant="subtitle2">
      Article number
      </Typography>
      <Typography display="inline" sx={{ mr: 1, fontWeight: '200' }} variant="subtitle2">
        {articleNumberString}
      </Typography>
      <Typography display="inline" fontWeight="bold" variant="subtitle2">
        Images uploaded
      </Typography>
      <Typography display="inline" sx={{ mr: 1, fontWeight: '200' }} variant="subtitle2">
        {imageUploadResponse?.imageMetadatas?.length}
      </Typography>
    </Box>
  );
};

export default UploadImageInfoBlock;

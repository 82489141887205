import React, { useState } from 'react';
import { IconButton, TextField, Typography, Box, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';
import onboardingAPI from '../../../../../services';
import { patchPricatUploadResponse } from '../../../../../features/data/dataSlice';

export const valueIsInvalid = (value) => {
  if (typeof value === 'string' || value instanceof String) {
    return false;
  }

  if (typeof value === 'number' || value instanceof Number) {
    return false;
  }

  if (typeof value === 'boolean' || value instanceof Boolean) {
    return false;
  }

  if (value == null) {
    return false;
  }

  return !value?.isValid;
};
export const pricatRowIsValid = (pricat) =>
  Object.keys(pricat).every((pricatDataKey) => !valueIsInvalid(pricat[pricatDataKey]));

const StyledIconButton = styled(IconButton)(() => ({
  ':hover': {
    color: '#EFEFEF',
  },
}));

const ValidationField = ({ invalidField, invalidDataRow }) => {
  const dispatch = useDispatch();
  const { pricatUploadResponse } = useSelector((state) => state.data);
  const [validatePricat, { isLoading }] = onboardingAPI.useValidatePricatFiledMutation();
  const [value, setValue] = useState(invalidField.value);

  const reValidate = async () => {
    // validate field on API
    const validationResult = await validatePricat({
      fieldForValidation: { ...invalidField, value },
      country: invalidDataRow.country,
      uuid: invalidDataRow.uuid,
    }).unwrap();
    const patchedPricat = pricatUploadResponse.pricatData.map((pricatRow) => {
      if (pricatRow.uuid === invalidDataRow.uuid) {
        let fieldId;
        if (invalidField.id === 'EAN'){
          fieldId = invalidField.id.toLowerCase();
        }
        else{
         fieldId = invalidField.id.charAt(0).toLowerCase() + invalidField.id.slice(1);
        }
        const upd = {
          [fieldId]: {
            id: validationResult.id,
            validationMessage: validationResult.validationMessage,
            isValid: validationResult?.isValid,
            value: validationResult.value,
          },
        };
        const updateExistedFiledWithValidation = { ...pricatRow, ...upd };
        return {
          ...updateExistedFiledWithValidation,
          validationError: !pricatRowIsValid(updateExistedFiledWithValidation),
        };
      }
      return pricatRow;
    });
    dispatch(
      patchPricatUploadResponse({
        ...pricatUploadResponse,
        pricatData: patchedPricat,
        hasValidationErrors: patchedPricat?.some((p) => !pricatRowIsValid(p)),
      })
    );
  };

  return (
    <>
      <Typography sx={{ mr: 1, fontWeight: '200' }} variant="subtitle2">
        {invalidField.id}
      </Typography>
      <TextField
        inputProps={{ style: { fontSize: 14, padding: 5, height: '14px' } }}
        defaultValue={invalidField.value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <Box display="flex" alignItems="center" justifyContent="center" style={{ width: '30px', height: '30px' }}>
        {isLoading ? (
          <CircularProgress size="23px" color="success" />
        ) : (
          <StyledIconButton aria-label="delete" size="small" onClick={reValidate}>
            <RefreshIcon />
          </StyledIconButton>
        )}
      </Box>
      <Typography sx={{ fontWeight: '200' }} variant="caption" color="red">
        {invalidField.validationMessage}
      </Typography>
    </>
  );
};

const ValidationRow = () => {
  const { pricatUploadResponse } = useSelector((state) => state.data);
  const invalidPricatDataRows = pricatUploadResponse?.pricatData?.filter((p) => p.validationError);

  if (!invalidPricatDataRows?.length) {
    return (
      <Typography align="center" variant="h5">
        No validation errors!
      </Typography>
    );
  }

  return (
    <Box
      display="grid"
      sx={{
        height: '390px',
        overflow: 'auto',
        alignContent: 'start',
      }}
      alignItems="center"
      gridTemplateColumns="min-content 1fr min-content 1fr"
    >
      {invalidPricatDataRows?.map((invalidPricatRow) => (
        <React.Fragment key={invalidPricatRow.uuid}>
          <Typography sx={{ gridColumn: '1/span 4', fontWeight: '600', mt: 2 }} variant="subtitle2" color="primary">
            {`${invalidPricatRow?.brand?.value} - ${invalidPricatRow?.productName?.value} (${invalidPricatRow?.productSKU?.value})`}
          </Typography>
          {Object.keys(invalidPricatRow)
            .reduce((acc, nextKey) => {
              if (valueIsInvalid(invalidPricatRow[nextKey])) {
                acc.push(invalidPricatRow[nextKey]);
              }
              return acc;
            }, [])
            .map((invalidField) => (
              <ValidationField key={invalidField.id} invalidField={invalidField} invalidDataRow={invalidPricatRow} />
            ))}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default ValidationRow;

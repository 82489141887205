import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const StyledHeader = styled(Typography)(() => ({
  borderBottom: '2px #3c4858 solid',
  fontWeight: 600,
  marginBottom: '20px',
  fontSize: '16px',
}));

const SectionHeader = ({ children }) => <StyledHeader variant="h5">{children}</StyledHeader>;

export default SectionHeader;

import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StyledUploadContainer from './StyledUploadContainer';

const UploadPricat = ({ isPricatUploading, handleUploadPricat }) => (
  <StyledUploadContainer>
    {isPricatUploading ? (
      <CircularProgress style={{ color: '#000000d9' }} />
    ) : (
      <label htmlFor="pricat" style={{ width: '100%', cursor: 'pointer' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <CloudUploadIcon />
          <input
            style={{ display: 'none' }}
            type="file"
            id="pricat"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={handleUploadPricat}
          />
          <Typography>UPLOAD PRICAT FILE</Typography>
        </Box>
      </label>
    )}
  </StyledUploadContainer>
);

export default UploadPricat;

import React from 'react';
import { Skeleton, Typography } from '@mui/material';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { DownloadLink, SectionHeader } from '../overview';
import onboardingAPI from '../../../services';

const PreviousUploads = () => {
  const { data: userData } = onboardingAPI.useGetUserQuery();
  const { data: vendorInfo } = onboardingAPI.useGetVendorInfoQuery(userData?.activeVendor, {
    skip: !userData?.activeVendor,
  });
  const {
    data: uploads,
    isLoading: isUploadsLoading,
    isFetching: isUploadsFetching,
  } = onboardingAPI.useGetUploadsQuery(vendorInfo?.key, {
    skip: !vendorInfo?.key,
  });

  const isLoading = isUploadsLoading || isUploadsFetching;

  if (isLoading) {
    return (
      <>
        <SectionHeader>10 RECENT UPLOADS</SectionHeader>
        <Typography variant="subtitle1" style={{ fontSize: '15px' }}>
          PRICAT
        </Typography>
        <Skeleton animation="wave" width="100%" />
        <Typography variant="subtitle1">LOOK BOOK</Typography>
        <Skeleton animation="wave" width="100%" />
      </>
    );
  }

  return (
    <>
      <SectionHeader>10 RECENT UPLOADS</SectionHeader>
      <Typography variant="subtitle1" style={{ fontSize: '15px' }}>
        PRICAT
      </Typography>
      {isLoading && <Skeleton animation="wave" width="100%" />}
      {uploads?.pricat.length ? (
        uploads.pricat.slice(0, 10).map((pricat) => (
          <div key={pricat.batchId}>
            <DownloadLink
              sx={{}}
              icon={PriceChangeIcon}
              label={pricat.filename}
              meta={pricat}
              // onClick={(e) => {
              //   e.preventDefault();
              //   // setFileToDelete(pricat);
              // }}
            />
          </div>
        ))
      ) : (
        <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: 300, paddingLeft: '10px' }}>
          No previous uploads
        </Typography>
      )}

      <Typography variant="subtitle1">Lookbook</Typography>
      {uploads?.lookbook.length ? (
        uploads.lookbook.map((lookbook) => (
          <DownloadLink
            key={lookbook.url}
            icon={PictureAsPdf}
            href={lookbook.url}
            label={lookbook?.name}
            onClick={() => {
              // e.preventDefault();
            }}
          />
        ))
      ) : (
        <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: 300, paddingLeft: '10px' }}>
          No previous uploads
        </Typography>
      )}
    </>
  );
};

export default PreviousUploads;

import React from 'react';
import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import styled from '@emotion/styled/macro';
import StoreIcon from '@mui/icons-material/Store';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import onboardingAPI from '../../services';
import Documents from './Documents';
import Profile from './Profile';

const CompanyNameCard = styled(Card)`
  margin-top: -20px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 10px;
  z-index: 1;
`;

const LoadingSkeleton = () => (
  <Box>
    <CompanyNameCard>
      <Skeleton animation="wave" width="300px" />
    </CompanyNameCard>
    <Box sx={{ p: 2, pt: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton animation="wave" width="100%" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton animation="wave" width="100%" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton animation="wave" width="100%" />
        </Grid>
      </Grid>
    </Box>
  </Box>
);

const Overview = () => {
  // const dispatch = useDispatch();
  // const { isAuthenticated } = useSelector((state) => state.auth);
  // const { selectedVendor } = useSelector((state) => state.data);
  const { data: userData, isLoading: isUserDataLoading } = onboardingAPI.useGetUserQuery();
  const { data: selectedVendor, isFetching: isVendorInfoFetching } = onboardingAPI.useGetVendorInfoQuery(
    userData?.activeVendor,
    {
      skip: !userData?.activeVendor,
    }
  );

  const [, { isLoading: isSetSelectedLoading }] = onboardingAPI.useSetSelectedVendorMutation({
    fixedCacheKey: 'set-vendor-fixed-key',
  });

  // tabs
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const backoffice = (event) =>
  {
    if (typeof window !== 'undefined') {
      console.log('we are running on the client')
  } else {
      console.log('we are running on the server');
  }
    const token = window.localStorage.getItem('token');
    console.log('token ', token);
  }

  return (
    <Grid container sx={{ position: 'relative' }} justifyContent="center" alignItems="center">    
      <Grid item xs={11}>
        <Card sx={{ mx: 1, mt: 4 }}>
          {isUserDataLoading ? (
            <LoadingSkeleton />
          ) : (
            <Box>
              {/* <CompanyNameCard>
                <Box display="flex" color="primary">
                  <StoreIcon color="primary" sx={{ mr: 1 }} />
                  {isVendorInfoFetching || isSetSelectedLoading ? (
                    <Skeleton animation="wave" width="300px" />
                  ) : (
                    <Typography sx={{ textAlign: 'center' }} width="300px" variant="body1" onClick={backoffice}>
                      {selectedVendor?.name}
                    </Typography>
                  )}
                </Box>
              </CompanyNameCard> */}
              <Tabs value={value} onChange={handleChange} aria-label="icon position tabs example">
                <Tab icon={<FormatListBulletedIcon />} label="documents" />
                <Tab icon={<HomeRepairServiceIcon />} iconposition="start" label="profile" />
              </Tabs>
              {value === 0 && <Documents />}
              {value === 1 && <Profile />}
              {/* <Box sx={{p: 2, pt: 5}}> */}
              {/*  {data?.Vendors?.map(({ Id, Name }) => ( */}
              {/*    <p>{Id}:{Name}</p> */}
              {/*  ))} */}
              {/* </Box> */}
            </Box>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Overview;

import React from 'react';
import { Container, Grid, TextField } from '@mui/material';
import styled from '@emotion/styled/macro';
import onboardingAPI from '../../services';

const StyledProfileField = styled(TextField)(({ theme }) => ({
  marginBottom: '10px',
  width: '100%',
  '.MuiInputLabel-root': {
    color: theme.palette.primary.main,
  },
  '.MuiInput-root:hover:not(.Mui-disabled):before, .MuiInput-root:hover:not(.Mui-disabled):after': {
    borderBottom: '1px dotted #d2d2d2',
  },
  '.MuiInputBase-root::before, .MuiInputBase-root::after': {
    borderBottom: '1px dotted #d2d2d2',
  },
}));

const Profile = () => {
  const { data: userData } = onboardingAPI.useGetUserQuery();
  const { data: vendorInfo } = onboardingAPI.useGetVendorInfoQuery(userData?.activeVendor, {
    skip: !userData?.activeVendor,
  });
  return (
    <Container disableGutters sx={{ p: 2 }}>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          {/* <StyledProfileField label="Key" variant="standard" value={vendorInfo.key || ''} /> */}
          <StyledProfileField label="Name" variant="standard" value={vendorInfo.name || ''} />
          <StyledProfileField label="Address" variant="standard" value={vendorInfo.adress || ''} />
          <StyledProfileField
            label="Organization Number"
            variant="standard"
            value={vendorInfo.organizationNumber || ''}
          />
          <StyledProfileField label="Sales Tax Group" variant="standard" value={vendorInfo.salesTaxGroup || ''} />
          <StyledProfileField label="Purchase Currency" variant="standard" value={vendorInfo.purchaseCurrency || ''} />
        </Grid>
        <Grid item xs={6}>
          <StyledProfileField label="Tax Exempt Number" variant="standard" value={vendorInfo.taxExemptNumber || ''} />
          <StyledProfileField label="Method Of Payment" variant="standard" value={vendorInfo.methodOfPayment || ''} />
          <StyledProfileField
            label="Bank Account Number"
            variant="standard"
            value={vendorInfo.bankAccountNumber || ''}
          />
          <StyledProfileField label="SWIFT" variant="standard" value={vendorInfo.swift || ''} />
          <StyledProfileField label="Line Discount" variant="standard" value={vendorInfo.lineDiscount || ''} />
          <StyledProfileField label="Primary Contact" variant="standard" value={vendorInfo.primaryContact || ''} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Profile;

import React, { useState } from 'react';
import Link from '@mui/material/Link';
import { Box, CircularProgress, ListItemText, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import onboardingAPI from '../../../services';

const DownloadLink = ({ href, label, meta, icon: Icon }) => {
  const { data: userData } = onboardingAPI.useGetUserQuery();
  const { data: vendorInfo } = onboardingAPI.useGetVendorInfoQuery(userData?.activeVendor, {
    skip: !userData?.activeVendor,
  });
  const [downloadLink, setDownloadLink] = useState(null);
  const [createExportFile, { isLoading: isLoadingCreateExport }] = onboardingAPI.useCreatePricatExportMutation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleGenerate = () => {
    if (downloadLink) {
      handleClose();
    } else {
      createExportFile({ batch: meta.batchId, vendorId: vendorInfo.key }).then((res) => {
        setDownloadLink(res.data);
      });
    }
  };

  return (
    <Box
      alignItems="start"
      justifyContent="start"
      flexDirection="column"
      sx={{ display: 'flex', mb: `${meta ? '0' : '10px'}` }}
    >
      {meta ? (
        <>
          <Button sx={{ fontSize: '12px', pb: '5px' }} display="flex" justify="center" onClick={handleClick}>
            <Icon fontSize="small" sx={{ mr: '10px' }} color="primary" />
            {label}
            <KeyboardArrowDownIcon fontSize="small" color="primary" />
          </Button>
          <div style={{ paddingLeft: '8px', position: 'relative', top: '-10px' }}>
            <Typography variant="caption" sx={{ mr: '10px' }}>
              {meta?.season}{' '}
            </Typography>
            <Typography variant="caption" sx={{ mr: '10px' }}>
              {meta?.uploadedByName}{' '}
            </Typography>
            <Typography variant="caption">{meta?.uploadDate}</Typography>
          </div>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem disabled={isLoadingCreateExport} sx={{ mx: '8px' }} onClick={handleGenerate}>
              {isLoadingCreateExport && <CircularProgress size="20px" color="success" />}
              <FileDownloadIcon sx={{ mr: '10px' }} fontSize="small" />
              {downloadLink ? (
                <Link target="_blank" rel="noopener noreferrer" href={downloadLink}>
                  Download generated file
                </Link>
              ) : (
                <ListItemText>Create export file</ListItemText>
              )}
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Box display="flex" justifyContent="center" sx={{ pl: '8px' }}>
          <Icon fontSize="small" sx={{ mr: '10px' }} color="primary" />
          <Link sx={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href={href}>
            {label}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default DownloadLink;
